import $ from 'jquery'
import './scss/index.scss'

const regionCodeToName = ['GMS', 'JMS', 'KMS', 'TMS', 'CMS', 'SEA'];

function regionNumToName(regionId) {
  return regionCodeToName[regionId] || regionId
}

let contentPromise = null

let [ region, version, loadPath ] = window.location.hash.substr(1).split(';')
loadPath = decodeURI(loadPath)
fetch('https://maplestory.io/api/wz?browsing=true').then((res) => res.json()).then((versions) => {
  versions.forEach(version => {
    let $treeNode = $(`<div data-region='${version.region}' data-version='${version.mapleVersionId}' data-path=''><span>${regionNumToName(version.region)} - ${version.mapleVersionId}</span></div>`);
    $treeNode.append('<div class="children"></div>')
    $('.tree').append($treeNode)
  })
}).then(() => {
  let regionVersionNode = $(`div[data-region=${region}][data-version=${version}]`)
  if (regionVersionNode && regionVersionNode.length)
    return ToggleNode(regionVersionNode[0])
}).then(WalkNode)

function WalkNode(nodeEntry) {
  let nodeEntryChildren = [...nodeEntry.childrenDom].sort((a, b) => b.path.length - a.path.length)
  for(let i = 0; i < nodeEntryChildren.length; ++i) {
    let thisNodePath = nodeEntryChildren[i].path
    if (loadPath.substr(0, thisNodePath.length) == thisNodePath)
      return ToggleNode(nodeEntryChildren[i].dom[0]).then(WalkNode)
  }
}

function ProcessNode(target, node) {
  const { region, version, path } = target.dataset
  contentPromise = null

  let links = $('<div class="links">')

  if (!node.value && target.parentElement && target.parentElement.parentElement) {
    const parentPath = ((target.parentElement.parentElement.dataset || {}).path || '').toLowerCase()
    let fetchPromise = null
    let seeMoreLink = null
    if (parentPath.endsWith('npc')) {
      seeMoreLink = [regionNumToName(region), version, 'npc', path.substr(path.lastIndexOf('/') + 1).replace('.img', '')].join('/')
      fetchPromise = fetch(`https://maplestory.io/api/${seeMoreLink}`)
    } else if (parentPath.endsWith('mob') || parentPath.endsWith('mob2')) {
      seeMoreLink = [regionNumToName(region), version, 'mob', path.substr(path.lastIndexOf('/') + 1).replace('.img', '')].join('/')
      fetchPromise = fetch(`https://maplestory.io/api/${seeMoreLink}`)
    } else if (parentPath.substr(0, parentPath.length - 1).endsWith('map')) {
      seeMoreLink = [regionNumToName(region), version, 'map', path.substr(path.lastIndexOf('/') + 1).replace('.img', '')].join('/')
      fetchPromise = fetch(`https://maplestory.io/api/${seeMoreLink}`)
    }
    if (fetchPromise != null && contentPromise == null) {
      contentPromise = fetchPromise
      links.append(`<a href='https://maplestory.wiki/${seeMoreLink}' target='_blank'>See more details</a>`)
      fetchPromise.then(subRes => subRes.text()).then(subRes => {
        if (fetchPromise != contentPromise) return
        $('.content').append(`<pre>${subRes}</pre>`).addClass('pre')
      })
    }
  }

  if (node.type > 0) links.append(`<a href="https://maplestory.io/api/wz/export/${region}/${version}/${path}" target="_blank">Export</a>`)

  if (links.children().length) $('.content').append(links)

  let children = []

  if (node.children.length) {

    node.children.sort((a, b) => {
      if (!isNaN(a) && !isNaN(b)) return a - b
      // else if (a.length != b.length) return a.length - b.length
      else return a.localeCompare(b)
    })

    children = node.children.map(subPath => {
      let thisNodePath = (path ? (path + '/') : '') + subPath
      return {
        region,
        version,
        path: thisNodePath,
        dom: $(`<div data-region='${region}' data-version='${version}' data-path='${thisNodePath}'><span>${subPath}</span><div class='children'></div></div>`)
      }
    })
    $(target).find('> div').append(children.map(nodeEntry => nodeEntry.dom))
  }
  else target.classList.add('no-children')
  switch (node.type) {
    case 12:
      $('.content').removeClass('pre').append('<img src="data:image/png;base64,'+node.value+'" />')
      break;
    case 11:
      $('.content').removeClass('pre').append('<audio src="data:audio/mpeg;base64,'+node.value+'" controls></audio>')
      break;
    case 9:
      $('.content').addClass('pre').append('<pre>' + JSON.stringify(node.value, null, 2) + '</pre>')
      break;
    case 3:
      $('.content').removeClass('pre').append(node.value == 1 ? 'true' : 'false')
      break;
    default:
      $('.content').removeClass('pre').append(node.value)
      break
  }

  return {
    childrenDom: children,
    node
  }
}

function ToggleNode(target) {
  if (target.classList.contains('open')) {
    target.classList.add('closed')
    target.classList.remove('open')
    $(target).find('> div > div').remove()
  } else if (target.classList.contains('closed')) {
    target.classList.remove('closed')
    target.classList.add('open')
  } else target.classList.add('open')
  const { region, version, path } = target.dataset
  window.location.hash = `#${region};${version};${encodeURI(path)}`
  $('.content').removeClass('pre').text('')

  return fetch(`https://maplestory.io/api/wz/${region}/${version}/${path}?browse=true`).then(res => res.json())
    .then(ProcessNode.bind(this, target))
}

$('.tree').on('click', function(e) {
  let target = e.target
  let isDownload = e.target.classList.contains('fa-file-archive-o') || e.target.tagName == 'A'
  if (isDownload) return;

  if (target && target.tagName != 'DIV') target = target.parentElement
  if (!target) return

  ToggleNode(target)
})